import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 711.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,711.000000) scale(0.100000,-0.100000)" >
				<path d="M2480 4736 c-116 -33 -221 -122 -261 -223 -22 -55 -24 -71 -24 -288
l0 -230 60 0 c33 0 500 -2 1038 -3 l977 -2 0 -253 c0 -183 -3 -256 -12 -265
-9 -9 -135 -12 -505 -12 l-493 0 0 -575 0 -575 253 -2 c138 -1 452 -1 697 1
411 3 449 5 498 23 98 36 182 120 228 228 18 43 19 83 22 930 3 917 2 963 -38
1040 -48 94 -131 165 -233 199 -60 21 -76 21 -1111 20 -809 -1 -1061 -4 -1096
-13z"/>
<path d="M5168 4545 c-36 -20 -48 -44 -48 -95 0 -73 53 -119 123 -106 47 9 78
46 84 99 5 38 1 48 -23 76 -35 39 -94 50 -136 26z m120 -41 c39 -43 26 -101
-29 -130 -58 -30 -119 8 -119 74 1 45 18 70 57 82 38 11 63 3 91 -26z"/>
<path d="M5177 4493 c-3 -10 -4 -29 -2 -43 2 -14 4 -33 4 -42 1 -25 20 -22 30
4 l8 22 19 -22 c18 -22 44 -30 44 -14 0 4 -5 13 -12 20 -9 9 -9 15 0 24 18 18
14 46 -7 58 -32 16 -79 12 -84 -7z m71 -25 c-7 -19 -38 -22 -38 -4 0 10 9 16
21 16 12 0 19 -5 17 -12z"/>
<path d="M2395 2370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2970 2070 c0 -101 2 -111 18 -108 12 2 18 16 22 52 5 51 25 74 54
63 12 -5 16 -19 16 -57 0 -36 4 -52 15 -57 24 -9 29 2 26 65 -2 70 -26 97 -78
89 -30 -5 -32 -4 -35 26 -2 21 -9 33 -20 35 -16 3 -18 -7 -18 -108z"/>
<path d="M3470 2070 c0 -103 1 -110 20 -110 19 0 20 7 20 110 0 103 -1 110
-20 110 -19 0 -20 -7 -20 -110z"/>
<path d="M2329 2159 c-6 -3 -8 -48 -7 -100 3 -86 5 -94 23 -94 16 0 21 8 23
38 3 37 4 37 48 37 32 0 44 4 44 15 0 11 -12 15 -45 15 -43 0 -45 1 -45 30 0
29 1 30 49 30 46 0 72 13 57 27 -8 8 -135 10 -147 2z"/>
<path d="M3156 2123 c-16 -22 -17 -28 -5 -37 8 -6 15 -31 16 -56 2 -29 8 -49
20 -57 18 -14 55 -17 66 -7 11 11 -5 34 -24 34 -29 0 -28 75 1 83 11 3 20 10
20 17 0 7 -9 14 -20 17 -11 3 -20 12 -20 19 0 23 -34 15 -54 -13z"/>
<path d="M2510 2038 c0 -70 2 -79 18 -76 13 2 18 15 20 56 3 46 6 53 28 58 14
4 24 13 24 23 0 14 -9 18 -45 18 l-45 0 0 -79z"/>
<path d="M2646 2099 c-48 -37 -28 -120 32 -134 31 -6 82 9 82 25 0 5 -20 10
-45 10 -33 0 -45 4 -45 15 0 12 13 15 55 15 58 0 63 6 43 43 -26 50 -77 61
-122 26z m82 -31 c3 -14 -3 -18 -26 -18 -35 0 -45 9 -30 27 15 19 52 12 56 -9z"/>
<path d="M2822 2104 c-41 -29 -24 -68 36 -80 18 -4 32 -13 32 -21 0 -11 -9
-13 -45 -7 -37 5 -45 3 -45 -9 0 -37 115 -31 129 6 10 26 -3 57 -24 57 -7 0
-26 5 -42 11 -22 8 -24 12 -13 20 8 5 27 6 42 3 15 -3 29 0 33 7 18 29 -65 40
-103 13z"/>
<path d="M3320 2113 c-46 -19 -61 -76 -31 -115 25 -32 67 -43 106 -28 35 13
23 28 -28 32 -54 4 -46 22 13 26 44 3 50 6 50 25 0 28 -9 41 -37 56 -23 12
-49 13 -73 4z m59 -36 c14 -18 4 -27 -31 -27 -30 0 -35 9 -16 28 16 16 33 15
47 -1z"/>
<path d="M3583 2110 c-46 -19 -55 -90 -16 -128 20 -20 104 -24 110 -5 8 24 2
28 -40 28 -41 0 -42 1 -42 35 0 35 0 35 44 35 51 0 63 16 26 33 -30 14 -51 14
-82 2z"/>
<path d="M3755 2108 c-28 -16 -44 -39 -45 -64 0 -31 36 -71 69 -79 33 -7 85
15 95 40 28 75 -50 143 -119 103z m79 -50 c16 -26 -4 -53 -40 -53 -23 0 -30 5
-32 23 -2 13 -1 30 2 38 8 20 56 14 70 -8z"/>
<path d="M3913 2105 c-2 -7 -3 -41 -1 -76 2 -49 7 -64 18 -64 11 0 16 13 18
47 3 51 21 73 51 63 16 -5 21 -16 23 -58 2 -40 7 -53 21 -55 14 -3 17 5 17 52
0 51 2 56 25 62 34 8 37 4 37 -48 0 -53 10 -73 32 -65 22 9 22 104 0 135 -16
24 -53 29 -85 11 -13 -7 -24 -7 -32 -1 -17 14 -118 11 -124 -3z"/>
<path d="M4545 2100 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4710 2102 c0 -5 11 -12 25 -14 14 -3 25 -1 25 3 0 5 -4 9 -9 9 -5 0
-16 3 -25 6 -9 3 -16 2 -16 -4z"/>
<path d="M4837 2099 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4202 1988 c2 -15 10 -23 23 -23 30 0 27 39 -3 43 -20 3 -23 0 -20
-20z"/>
<path d="M4788 1218 c-5 -37 -5 -240 -1 -445 l6 -233 648 0 649 0 0 -270 0
-270 375 0 375 0 0 270 0 270 180 0 180 0 0 345 0 345 -1205 0 c-956 0 -1205
-3 -1207 -12z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
